<template>
    <div class="page_content">
        <div class="page_edit_content">
            <Form
                ref="form"
                @handleChange="handleChange"
                :form_data="form_data"
                @handleSubmit="submit"
            >
                <div slot="transfer">
                    <!-- showSearch -->
                    <!-- <a-transfer
            :dataSource="user_list"
			showSearch
            
            :filterOption="filterOption"
            :targetKeys="targetKeys"
            @change="transfer_change"
            @search="handleSearch"
            
            @selectChange="trans_select"
			:showSelectAll="false"
          >

  		/>
		  	
                    </a-transfer>-->
                    <TransferTree
                        @change="TransferTreeChange"
                        :treeData.sync="treeData"
                        :value.sync="treeDataValue"
                    />
                    <a-input type="hidden" name="rule_user" v-decorator="['rule_user',rule_user]" />
                </div>
                <div slot="exclude_data">
                    <a-date-picker :allowClear="false" @change="get_date" />
                    <a-input
                        type="hidden"
                        name="exclude_data"
                        v-decorator="['exclude_data',exclude_data]"
                    />
                    <div class="date" v-if="date_group.length">
                        <!-- <div v-for="(item,index) in date_group" :key="index" class=""> -->
                        <a-button
                            @click="del_date(item)"
                            class="date_btn"
                            v-for="(item,index) in date_group"
                            :key="index"
                            size="small"
                        >
                            {{item}}
                            <a-icon type="close-circle" />
                        </a-button>
                        <!-- </div> -->
                        <!-- <div v-for="(item,index) in date_group" :key="index"><span style="color:#1890ff">{{item}}</span>  /  </div> -->
                    </div>
                </div>
                <div slot="rule_info">
                    <a @click="add_time()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left:5px">新增考勤时间/地点</span>
                    </a>
                    <a-input type="hidden" name="rule_info" v-decorator="['rule_info',rule_info]" />
                    <div class="times" v-for="(item,index) in address_list" :key="index">
                        <div class="item_box">
                            <ul class="time_item">
                                <li>
                                    <span>上班时间：</span>
                                    <a-button size="small">
                                        {{item[0].start_time ? item[0].start_time :'未设置'}}
                                        <a-icon type="clock-circle" />
                                    </a-button>
                                </li>
                                <li>
                                    <span>是否打卡：</span>
                                    <span>{{item[0].is_punch_card==1 ? '是' :'否'}}</span>
                                </li>
                                <li>
                                    <span>打卡时间：</span>
                                    <a-button size="small">
                                        {{item[0].punch_card_time ? item[0].punch_card_time.start_time : '未设置'}}~{{item[0].punch_card_time.end_time ? item[0].punch_card_time.end_time : '未设置'}}
                                        <a-icon type="clock-circle" />
                                    </a-button>
                                </li>
                                <li v-if="item[0].punch_card_area.length">
                                    <span>考勤地点：</span>
                                    <div>
                                        <span
                                            style="display:block"
                                            v-for="(vo,io) in item[0].punch_card_area"
                                            :key="io"
                                        >
                                            <span
                                                style="display:block"
                                            >{{vo.address_name? vo.address_name : '未设置考勤地点'}}{{vo.address_name ? ' | ' : ''}}{{vo.address_name&&vo.distance ? vo.distance: ''}}</span>
                                        </span>
                                    </div>
                                    <div class="clear"></div>
                                </li>
                            </ul>
                            <ul class="time_item">
                                <li>
                                    <span>下班时间：</span>
                                    <a-button size="small">
                                        {{item[1].end_time ? item[1].end_time : '未设置'}}
                                        <a-icon type="clock-circle" />
                                    </a-button>
                                </li>
                                <li>
                                    <span>是否打卡：</span>
                                    <!--  style="color:#1890ff" -->
                                    <span>{{item[1].is_punch_card==1 ? '是' :'否'}}</span>
                                </li>
                                <li>
                                    <span>打卡时间：</span>
                                    <a-button size="small">
                                        {{item[1].punch_card_time ? item[1].punch_card_time.start_time : '未设置'}}~{{item[1].punch_card_time.end_time ? item[1].punch_card_time.end_time : '未设置'}}
                                        <a-icon type="clock-circle" />
                                    </a-button>
                                </li>
                                <li v-if="item[1].punch_card_area.length">
                                    <span>考勤地点：</span>
                                    <div>
                                        <span
                                            style="display:block"
                                            v-for="(vo,io) in item[1].punch_card_area"
                                            :key="io"
                                        >
                                            <span
                                                style="display:block"
                                            >{{vo.address_name ? vo.address_name : '未设置考勤地点'}}{{vo.address_name ? ' | ' : ''}}{{vo.address_name&&vo.distance ? vo.distance: '未设置考勤范围'}}</span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                            <ul class="time_item">
                                <!-- <li></li> -->
                            </ul>
                        </div>
                        <a class="time_del" @click="del_rule_info(item)">删除</a>
                    </div>
                </div>
            </Form>
        </div>
        <EditPop
            @handleSubmit="address_submit"
            :width="'600px'"
            :form_data="form_data_seo"
            :visible.sync="visible"
        >
            <div slot="clock_in_address">
                <a @click="add_address('clock_in')">
                    <a-icon type="plus-circle" />
                    <span style="padding-left:5px">新增考勤地点</span>
                </a>
                （不添加默认任意地点可打卡）
                <a-input type="hidden" name="address" v-decorator="['address',address]" />
                <div class="times" v-for="(item,index) in clock_in_address" :key="index">
                    <div class="map_box">
                        <div class="address">
                            <span>{{item.address_name}}</span>
                            |
                            <span>{{item.distance}}</span>
                        </div>
                        <div>{{item.address}}</div>
                    </div>
                    <a class="time_del" @click="del_address('clock_in',item)">删除</a>
                </div>
            </div>
            <div slot="clock_out_address">
                <a @click="add_address('clock_out')">
                    <a-icon type="plus-circle" />
                    <span style="padding-left:5px">新增考勤地点</span>
                </a>
                （不添加默认任意地点可打卡）
                <a-input type="hidden" name="address" v-decorator="['address',address]" />
                <div class="times" v-for="(item,index) in clock_out_address" :key="index">
                    <div class="map_box">
                        <div class="address">
                            <span>{{item.address_name}}</span>
                            |
                            <span>{{item.distance}}</span>
                        </div>
                        <div>{{item.address}}</div>
                    </div>
                    <a class="time_del" @click="del_address('clock_out',item)">删除</a>
                </div>
            </div>
        </EditPop>
        <Map
            @handleSubmit="address_submit_1"
            :form_data="map_data_seo"
            :visible.sync="address_visible"
        >
            <!-- 地图 -->
            <div style="padding-bottom:20px" slot="map" class="map_select">
                <simple-map @get_title="get_title"></simple-map>
            </div>
        </Map>
    </div>
</template>

<script>
import EditPop from "@/components/EditPop";
import Map from "@/components/EditPop";
import Form from "@/components/Form";
import SimpleMap from "@/components/BaiduMap";
import TransferTree from "@/components/TransferTree";
import { getRuleData, getUserList, modify } from "@/api/personnel";

const treeData = [];

export default {
    components: {
        Form,
        EditPop,
        SimpleMap,
        Map,
        TransferTree
    },
    data() {
        return {
            treeData: [],
            treeDataValue: [],
            expand: false,
            page: 1,
            pagination: { ...this.$config.pagination },
            value: "",
            user_list: [],
            address: {},
            address_list: [],
            rule_info: {},
            rule_user: {},
            time_group: [],
            clock_out_time: "",
            clock_in_time: "",
            clock_in_address: [],
            clock_out_address: [],
            visible: false,
            date_group: [],
            exclude_data: {},
            address_visible: false,
            targetKeys: [],
            form_data_seo: {
                title: "添加考勤时间/地点",
                list: [
                    {
                        type: "slot",
                        name: "clock_in_address",
                        title: "上班打卡地址",
                        format: "HH:mm",
                        options: {}
                    },
                    {
                        type: "time-picker",
                        name: "clock_in_time",
                        title: "上班时间",
                        format: "HH:mm",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请填写上班时间"
                                }
                            ],
                        }
                    },
                    {
                        type: "radio",
                        name: "clock_in_clock",
                        title: "上班打卡",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请选择是否打卡"
                                }
                            ]
                        },
                        list: [
                            {
                                key: "1",
                                value: "是"
                            },
                            {
                                key: "0",
                                value: "否"
                            }
                        ]
                    },
                    {
                        type: "time_range",
                        start: {
                            name: "start_clock_in",
                            format: "HH:mm",
                            options: {
                                rules: [
                                    {
                                        required: true,
                                        message: "请填写上班打卡范围"
                                    }
                                ],
                            }
                        },
                        end: {
                            name: "start_clock_out",
                            format: "HH:mm",
                            options: {
                                rules: [
                                    {
                                        required: true,
                                        message: "请填写上班打卡范围"
                                    }
                                ],
                            }
                        },
                        title: "上班打卡范围",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请填写上班打卡范围"
                                }
                            ],
                        }
                    },
                    {
                        type: "checkbox",
                        name: "start_is_field_punch_card",
                        title: "允许外勤打卡",
						options: {},
						hidden:true,
                        list: [
                            {
                                key: "1",
                                value: "允许外勤打卡"
                            }
                        ]
                    },
                    {
                        type: "slot",
                        name: "clock_out_address",
                        title: "下班打卡地址",
                        format: "HH:mm",
                        options: {}
                    },
                    {
                        type: "time-picker",
                        name: "clock_out_time",
                        title: "下班时间",
                        format: "HH:mm",

                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请填写下班时间"
                                }
                            ],
                        }
                    },
                    {
                        type: "radio",
                        name: "clock_out_clock",
                        title: "下班打卡",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请选择是否打卡"
                                }
                            ]
                        },
                        list: [
                            {
                                key: "1",
                                value: "是"
                            },
                            {
                                key: "0",
                                value: "否"
                            }
                        ]
                    },
                    {
                        type: "time_range",
                        start: {
                            name: "end_clock_in",
                            format: "HH:mm",
                            options: {
                                rules: [
                                    {
                                        required: true,
                                        message: "请填写下班打卡范围"
                                    }
                                ],
                            }
                        },
                        end: {
                            name: "end_clock_out",
                            format: "HH:mm",
                            options: {
                                rules: [
                                    {
                                        required: true,
                                        message: "请填写下班打卡范围"
                                    }
                                ],
                            }
                        },
                        title: "下班打卡范围",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请填写下班打卡范围"
                                }
                            ],
                        }
                    },
                    {
                        type: "checkbox",
                        name: "end_is_field_punch_card",
						title: "允许外勤打卡",
						hidden:true,
                        options: {},
                        list: [
                            {
                                key: "1",
                                value: "允许外勤打卡"
                            }
                        ]
                    }
                ]
            },
            map_data_seo: {
                title: "添加考勤地点",
                list: [
                    {
                        type: "select",
                        name: "distance",
                        title: "打卡范围",
                        tooltip: "员工可在该范围内正常打卡",
                        options: {
                            initialValue: 300
                        },
                        list: [
                            {
                                value: "300m",
                                key: 300
                            },
                            {
                                value: "400m",
                                key: 400
                            },
                            {
                                value: "800m",
                                key: 800
                            },
                            {
                                value: "1公里",
                                key: 1000
                            },
                            {
                                value: "2公里",
                                key: 2000
                            },
                            {
                                value: "3公里",
                                key: 3000
                            }
                        ]
                    }
                ]
            },
            map: {},
            form_data: {
                title: "添加考勤规则",
                show_submit_btn: true,
                show_close_btn: true,
                close_reset_btn: true,
                list: [
                    {
                        type: "text",
                        name: "id",
                        hidden: true,
                        options: {
                            initialValue: ""
                        }
                    },
                    {
                        type: "text",
                        name: "rule_name",
                        title: "考勤规则名称",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请填写考勤规则名称"
                                }
                            ],
                            initialValue: ""
                        }
                    },
                    {
                        type: "slot",
                        name: "transfer",
                        title: "参与考勤人员",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请填写考勤规则名称"
                                }
                            ],
                            initialValue: ""
                        }
                    },
                    {
                        type: "slot",
                        name: "exclude_data",
                        title: "休息日",
                        mode: "default",
                        options: {
                            initialValue: ""
                        }
                    },
                    {
                        type: "slot",
                        name: "rule_info",
                        title: "考勤时间/地点",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请设置考勤配置"
                                }
                            ],
                            initialValue: ""
                        }
                    },
                    {
                        type: "checkbox",
                        name: "start",
                        title: "生效时间",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请选择生效时间!"
                                }
                            ],
                            initialValue: "1"
                        },
                        list: [{
                            key: '1',
                            value: '明日生效'
                        }]
                    },

                    {
                        type: "text",
                        name: "remark",
                        title: "备注",
                        // close:true,
                        options: {
                            initialValue: ""
                        }
                    },

                    {
                        type: "textarea",
                        name: "start_work_saying",
                        title: "上班激励语",
                        placeholder:
                            "多个词条用###隔开，例如：今天辛苦一点，明天就舒服一点；###因为自信，所以成功！",
                        options: {
                            initialValue: ""
                        }
                    },
                    {
                        type: "textarea",
                        name: "end_work_saying",
                        title: "下班激励语",
                        placeholder:
                            "多个词条用###隔开，例如：今天辛苦一点，明天就舒服一点；###因为自信，所以成功！",
                        options: {
                            initialValue: ""
                        }
                    }
                ]
            },
            map_show: false,
            list: {},
            clock_type: ""
        };
    },
    async created() {
        let id = this.$router.currentRoute.query.id;


        this.get_list();

        this.$method.get_user_department().then(res => {
            this.treeData = res;
            if (id) {
                this.get_rule(id);
            }

        });
        // this.treeDataValue = ['4252','3637'];
    },
    methods: {
        TransferTreeChange(value) {
            this.$refs.form.setFieldsValue({
                rule_user: JSON.stringify(value)
            });
        },

        del_address(type, params) {
            if (type == "clock_in") {
                this.clock_in_address.forEach((item, index) => {
                    if (params == item) {
                        this.clock_in_address = this.clock_in_address
                            .slice(0, index)
                            .concat(
                                this.clock_in_address.slice(
                                    index + 1,
                                    this.clock_in_address.length
                                )
                            );
                    }
                });
            } else {
                this.clock_out_address.forEach((item, index) => {
                    if (params == item) {
                        this.clock_out_address = this.clock_out_address
                            .slice(0, index)
                            .concat(
                                this.clock_out_address.slice(
                                    index + 1,
                                    this.clock_out_address.length
                                )
                            );
                    }
                });
            }
        },
        address_submit_1(e) {
            let values = e.values;
            let obj = {
                ...values,
                longitude: this.map.longitude,
                latitude: this.map.latitude,
                address_name: this.map.address_name,
                address: this.map.address
            };
            if (this.clock_type == "clock_in") {
                this.clock_in_address.push(obj);
            } else {
                this.clock_out_address.push(obj);
            }
            this.address_visible = false;
        },
        async get_rule(e) {
            try {
                await getRuleData({ id: e || "" }).then(res => {
                    let list = res.data;
                    this.form_data.list.forEach(item => {
                        for (let vo in list) {
                            if (item.name == vo) {
                                if (item.type == "date") {
                                    item.options.initialValue = this.$moment(
                                        list[vo],
                                        "YYYY/MM/DD"
                                    );
                                } else {
                                    if (list[vo] != null) {
                                        item.options.initialValue = list[vo].toString();
                                    }
                                }
                            } else if (item.type == "slot") {
                                if (item.name == "rule_info") {
                                    if (list.rule_info && list.rule_info.length) {
                                        this.address_list = list.rule_info;
                                        this.$refs.form.setFieldsValue({
                                            rule_info: JSON.stringify(list.rule_info)
                                        });
                                    } else {
                                        this.address_list = [];
                                        this.$refs.form.setFieldsValue({
                                            rule_info: JSON.stringify(this.address_list)
                                        });
                                    }
                                } else if (item.name == "exclude_data") {
                                    if (list.exclude_data) {
                                        this.date_group = list.exclude_data.split(",");
                                        this.$refs.form.setFieldsValue({
                                            exclude_data: JSON.stringify(this.date_group)
                                        });
                                    }
                                } else if (item.name == "transfer") {
                                    if (list.rule_user && list.rule_user.length) {
                                        let user = list.rule_user;
                                        let treeDataValue = [];
                                        user = user.map(io => {
                                            treeDataValue.push("user_" + io.id)
                                            io.key = "user_" + io.id;
                                            io.title = io.username;
                                            return io;
                                        });

                                        this.treeDataValue = treeDataValue;


                                        // this.treeDataValue = user;
                                        this.$refs.form.setFieldsValue({
                                            rule_user: JSON.stringify(this.treeDataValue)
                                        });
                                    } else {
                                        this.treeDataValue = [];
                                        this.$refs.form.setFieldsValue({
                                            rule_user: JSON.stringify([])
                                        });
                                    }
                                }
                            }
                        }
                    });
                });
            } catch { }
        },
        get_user_list(item) {
            for (let i = 0; i < item.length; i++) {
                item[i].title = item[i].name;
                item[i].value = item[i].id;
                item[i].key = item[i].id;
                if (Array.isArray(item[i].sub) && item[i].sub.length) {
                    item[i].children = item[i].sub || [];
                    this.get_depart_list(item[i].sub);
                }
            }
            return item;
        },
        async get_list(data) {
            try {
                let list = await getUserList({
                    _this: this,
                    data
                });
                let data = list.res.data.list.data;
                let moc = [];
                data.forEach(item => {
                    moc.push({
                        key: item.id,
                        title: item.username,
                        chosen: false,
                        description: ""
                    });
                });

                const mockData = [];
                for (let i = 0; i < 20; i++) {
                    mockData.push({
                        key: i.toString(),
                        title: `content${i + 1}`,
                        description: `description of content${i + 1}`,
                        disabled: i % 4 === 0
                    });
                }

                this.user_list = mockData; //moc;
            } catch (e) {
            }
        },
        get_title(e) {
            let map = {
                address_name: e.title,
                longitude: e.point.lng,
                latitude: e.point.lat,
                address: e.address
            };
            this.map = map;
        },
        add_time() {
            this.visible = true;
        },
        del_date(params) {
            this.date_group.forEach((item, index) => {
                if (params == item) {
                    this.date_group = this.date_group
                        .slice(0, index)
                        .concat(this.date_group.slice(index + 1, this.date_group.length));
                }
            });

            this.$refs.form.setFieldsValue({
                exclude_data: JSON.stringify(this.date_group)
            });
        },
        del_rule_info(params) {
            this.address_list.forEach((item, index) => {
                if (params == item) {
                    this.address_list = this.address_list
                        .slice(0, index)
                        .concat(
                            this.address_list.slice(index + 1, this.address_list.length)
                        );
                }
            });

            this.$refs.form.setFieldsValue({
                rule_info: JSON.stringify(this.address_list)
            });
        },
        get_date(e) {
            let date = this.$method.get_time(e, "YYYY-MM-DD");
            this.date_group.push(date);
            this.$refs.form.setFieldsValue({
                exclude_data: JSON.stringify(this.date_group)
            });
        },
        add_address(val) {
            this.clock_type = val;
            this.address_visible = true;
        },
        onChange(time, timeString) {
        },
        address_submit(e) {
            let values = e.values;
            let arr = [];

            let start_punch_card_time = {
                start_time: this.$method.get_time(values["start_clock_in"]),
                end_time: this.$method.get_time(values["start_clock_out"])
            };
            let end_punch_card_time = {
                start_time: this.$method.get_time(values["end_clock_in"]),
                end_time: this.$method.get_time(values["end_clock_out"])
            };
            let start = {
                start_time: this.$method.get_time(values["clock_in_time"]),
                is_punch_card: values.clock_in_clock,
                punch_card_time: start_punch_card_time,
                punch_card_area: [...this.clock_in_address],
                is_field_punch_card: values.start_is_field_punch_card
                    ? values.start_is_field_punch_card[0]
                    : 0
            };

            let end = {
                end_time: this.$method.get_time(values["clock_out_time"]),
                is_punch_card: values.clock_out_clock,
                punch_card_time: end_punch_card_time,
                punch_card_area: [...this.clock_out_address],
                is_field_punch_card: values.end_is_field_punch_card
                    ? values.end_is_field_punch_card[0]
                    : 0
            };
            arr.push(start, end);

            this.address_list.push(arr);
            this.$refs.form.setFieldsValue({
                rule_info: JSON.stringify(this.address_list)
            });
            this.visible = false;
        },
        submit(e) {
            let data = e.values;
            let exclude_data = []
            if (data.exclude_data && data.exclude_data != '[]') {
                exclude_data = JSON.parse(data.exclude_data);
            }
            let rule_user = []
            if (data.rule_user && data.rule_user != '[]') {
                rule_user = JSON.parse(data.rule_user);
            }
            let obj = {
                ...data,
                exclude_data: exclude_data.join(","),
                rule_user: rule_user.join(",")
            };
            try {
                modify({
                    data: obj,
                    info: true,
                }).then(res => {
                    this.$router.go(-1);
                })
            } catch { }
        },
        filterOption(inputValue, option) {
            return option.description.indexOf(inputValue) > -1;
        },
        transfer_change(targetKeys, direction, moveKeys) {
            this.targetKeys = targetKeys;
            this.$refs.form.setFieldsValue({
                rule_user: JSON.stringify(targetKeys)
            });
        },
        trans_select(e) {
        },
        handleSearch(dir, value) {
        },
        handleChange(data) {
        }
    }
};
</script>

<style lang="less" scoped>
.map_select {
    position: relative;
    .serach_input {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}
.clear {
    clear: both;
}
.times {
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    line-height: 26px;
    padding: 10px 0;
    .time_del {
        display: block;
        padding: 0 10px;
        min-width: 70px;
    }
    .map_box {
        // color:rgba(0, 0, 0, 0.65);
        padding-left: 10px;
    }
    .time_item {
        display: block;
        margin-bottom: 0;
        padding: 0;
        // div {
        //   padding-left: 10px;
        // }
        li {
            margin-bottom: 10px;
            // display: flex;
            padding-left: 10px;
            list-style: none;
            float: left;
            // div{
            //   float: left;

            // }
        }
    }
}
.date {
    display: flex;
    align-items: center;
    line-height: 30px;
    flex-wrap: wrap;
    border: 1px solid #d9d9d9;
    padding: 0 10px 10px;
    margin-top: 10px;
    .date_btn {
        margin-right: 10px;
        margin-top: 10px;
    }
}
</style>
